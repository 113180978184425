import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "How to create a side menu component with ReactJS",
  "description": "Learn to create side menu component using React and CSS",
  "date": "2020-01-06",
  "image": "sidemenu.jpg",
  "tags": ["css", "react"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In today's tutorial, I will show you how to create a side menu component using React and CSS`}</p>
    <h2>{`Demo`}</h2>
    <div {...{
      "style": {
        "textAlign": "center"
      }
    }}>{`
    `}<img parentName="div" {...{
        "src": "/649b4dd194ba4ae24287b18f1f8fc451/demo.gif",
        "alt": "demo"
      }}></img>
    </div>
    <p>{`What we want to create in this tutorial is a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SideMenu`}</code>{` will slide from the left of our page when users click on the hamburger icon.
The menu would slide back to the left if users click on the overlay outside of the component`}</p>
    <h2>{`Implementation`}</h2>
    <p>{`Let's create a React by using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`create-react-app`}</code></p>
    <h3>{`Initialize the project`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`npx create-react-app sidemenu-component`}</code></pre></div>
    <p>{`After that, run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm start`}</code>{` to boost up the project and you will have a working React project set up for you.`}</p>
    <p>{`In your project, you will have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.js`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.css`}</code>{` as our main component and its styling. Let's remove all the unwanted css and jsx. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.js`}</code>{` will look like this`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// App.js`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`header className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app-header"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`App header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` App`}</code></pre></div>
    <p>{`In the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.css`}</code>{`, we can remove everything except for the App-header. I changed it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`app-header`}</code>{` to make it consistent with other classes.`}</p>
    <p>{`Our page will look like this:`}</p>
    <div {...{
      "style": {
        "textAlign": "center"
      }
    }}>{`
    `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3580a93579395a9d9526347b9259bc2/5b6ee/step-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.123552123552116%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAVElEQVQoz2MQk1L8Ly6t/F9KTh2I1SjGDHPmLfy/cPGy/4uWLP+/aPFyCE0BZvhPZcDw+fOX/3/+/Pn/+/dvqmCG79+/g03+9+8fGFPswlEDB5+BAK/X0XD2SJtHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "step-1",
            "title": "step-1",
            "src": "/static/c3580a93579395a9d9526347b9259bc2/e3189/step-1.png",
            "srcSet": ["/static/c3580a93579395a9d9526347b9259bc2/a2ead/step-1.png 259w", "/static/c3580a93579395a9d9526347b9259bc2/6b9fd/step-1.png 518w", "/static/c3580a93579395a9d9526347b9259bc2/e3189/step-1.png 1035w", "/static/c3580a93579395a9d9526347b9259bc2/44d59/step-1.png 1553w", "/static/c3580a93579395a9d9526347b9259bc2/5b6ee/step-1.png 1672w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </div>
    <br />
    <h3>{`Creating SideMenu and basic styling`}</h3>
    <p>{`Now, let's create the SideMenu Component.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// SideMenu.js`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Component `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SideMenu`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`aside className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"side-menu"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"side-menu__overlay"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"side-menu__content"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Side bar`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`aside`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` SideMenu`}</code></pre></div>
    <p>{`We will have a div inside our SideMenu to act as an overlay for our SideMenu.`}</p>
    <p>{`Next, here are some basic styling to our SideMenu Component`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/*App.css*/`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` fixed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`overflow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` hidden`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`pointer-events`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` none`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 25`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu__content`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-sizing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 10`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 70%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`max-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 26rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #fff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0 2rem `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 255`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu__overlay`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #0c1066`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`It is very straightforwards. The SideMenu expand whole view width and height with a fixed position while the content will take up about 70% and has a fix number of max-width.
The overlay will take up 100% of width and height of its parent and have an opacity of 0 when the menu is not active. It will have a non-zero opacity when the menu is active.`}</p>
    <p>{`Don't forget to have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pointer-events: none;`}</code>{` on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu`}</code>{`. Let's just leave it there for now. I will explain more about that later on.`}</p>
    <h3>{`Icon Component`}</h3>
    <p>{`The SideMenu will show up when we click on the icon and it will take `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fill`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onMenuClick`}</code>{` as props.
I am using a svg, you can use whatever element you wish as long as it has a click event.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Hamburger.js`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Hamburger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` fill `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'#594e78'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` onMenuClick `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`svg
      onClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`onMenuClick`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Menu_Burger_Icon"`}</span>{`
      data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Menu Burger Icon"`}</span>{`
      viewBox`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"31.5 30 49.9 32"`}</span>{`
      fill`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`fill`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`rect
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rectangle_9"`}</span>{`
        width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"49.9"`}</span>{`
        height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span>{`
        className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"hamburger__icon__fill"`}</span>{`
        data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rectangle 9"`}</span>{`
        rx`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span>{`
        transform`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"translate(31.5 58)"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`rect
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rectangle_10"`}</span>{`
        width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"49.9"`}</span>{`
        height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span>{`
        className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"hamburger__icon__fill"`}</span>{`
        data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rectangle 10"`}</span>{`
        rx`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span>{`
        transform`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"translate(31.5 44)"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`rect
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rectangle_11"`}</span>{`
        width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"49.9"`}</span>{`
        height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span>{`
        className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"hamburger__icon__fill"`}</span>{`
        data`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rectangle 11"`}</span>{`
        rx`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span>{`
        transform`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"translate(31.5 30)"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`svg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` Hamburger`}</code></pre></div>
    <p>{`(the source code of svg above is from `}<a href="https://github.com/hamedbaatour/minimus/blob/master/src/app/app.component.html#L33" target="_blank">{`here`}</a>{`. It was written for an Angular project but I modified it a bit to fit a React project)`}</p>
    <h3>{`Putting all together`}</h3>
    <p>{`We can add these two components to our main `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.js`}</code>{` file. The main idea is to make the icon a toggle button.
This means that our App component will have a state called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isMenuActive`}</code>{` to keep track of SideMenu Component active state.
As `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.js`}</code>{` was created as a stateless component, we can use React Hook `}<a href="https://reactjs.org/docs/hooks-state.html" target="_blank">{`useState`}</a>{` to avoid rewriting our component.`}</p>
    <p>{`If you are not familiar with React Hook, I suggest you go to the website and have a quick read about `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useState`}</code>{`. It is a very straightforward and short read.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useState `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./App.css'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Hamburger `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./Hamburger'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` SideMenu `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./SideMenu'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` activeMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`header className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app-header"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Logo here`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"icon"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Hamburger
            fill`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"#fff"`}</span>{`
            onMenuClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`activeMenu`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`SideMenu isMenuActive`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` App`}</code></pre></div>
    <p>{`The initial state for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isMenuActive`}</code>{` is false. Right now, the SideMenu won't be visible. let's set it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` to make sure our components are rendered properly.
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`activeMenu`}</code>{` will be our callback function to set SideMenu active state. It will set state to the opposite state whenever the icon is clicked.`}</p>
    <p>{`Here is what we have got:`}</p>
    <undefined><div {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1035px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7ed145520aacabab06c18ffcfc013c1a/17d12/step-2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "85.71428571428571%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAkUlEQVQ4y2P48ePHfxD49+8fGCODv3//guknT5//V9U0+S8urfJfSl79v5ScGhJW/y8ho/pfSU3vv6Gp7X8GfAbC+J8/f/2/dPna/wsXL/u/aMlyTLx4+f/FS1cA1azCbyCyocQColz49++//z9+/v7/+zdhTMBAWFj+///7D0IMn6NHDRw1cNTAwW3gHyINBABLbSMKA8mtagAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "step-2",
              "title": "step-2",
              "src": "/static/7ed145520aacabab06c18ffcfc013c1a/e3189/step-2.png",
              "srcSet": ["/static/7ed145520aacabab06c18ffcfc013c1a/a2ead/step-2.png 259w", "/static/7ed145520aacabab06c18ffcfc013c1a/6b9fd/step-2.png 518w", "/static/7ed145520aacabab06c18ffcfc013c1a/e3189/step-2.png 1035w", "/static/7ed145520aacabab06c18ffcfc013c1a/44d59/step-2.png 1553w", "/static/7ed145520aacabab06c18ffcfc013c1a/17d12/step-2.png 1666w"],
              "sizes": "(max-width: 1035px) 100vw, 1035px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>
      </div>
      <br></br></undefined>
    <p>{`Now, we need to make our SideMenu Component to be aware of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isMenuActive`}</code>{` and when the overlay is clicked, so we will pass `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isMenuActive`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onOverLayClick`}</code>{` as props.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` useState `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./App.css'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Hamburger `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./Hamburger'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` SideMenu `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'./SideMenu'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` activeMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`header className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app-header"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Logo here`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"icon"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Hamburger
            fill`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"#fff"`}</span>{`
            onMenuClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`activeMenu`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`SideMenu
          isMenuActive`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          onOverLayClick`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`activeMenu`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`!`}</span>{`isMenuActive`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` App`}</code></pre></div>
    <p>{`When the menu is active, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu__content`}</code>{` would have new active classes which are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu--active`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu__content--active`}</code>{`.
We also will need to bind the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onOverLayClick`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{` handler.
I am using a library called `}<a href="https://github.com/JedWatson/classnames" target="_blank">{`classnames`}</a>{` to make it easier to manage class names.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// SideMenu.js`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Component `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` classnames `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'classnames'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` PropTypes `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'prop-types'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SideMenu`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` onOverLayClick `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`props

`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` sideMenuClasses `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`classnames`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'side-menu'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'side-menu--active'`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` isMenuActive`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` sideMenuContentClasses `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`classnames`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`'side-menu__content'`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'side-menu__content--active'`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` isMenuActive`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`aside className`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`sideMenuClasses`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`div className`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`"side-menu__overlay"`}</span>{` onClick`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`onOverLayClick`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`div className`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`sideMenuContentClasses`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`          Side bar
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`aside`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Below is what we have got so far: `}</p>
    <undefined><div {...{
        "style": {
          "textAlign": "center"
        }
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1035px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/32fd7f0ab578d29c7a122bd539d93f34/b918a/step-3.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.73745173745174%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABnklEQVQoz52SzW4SURiGuRGNMMP8nmGGMwMMUwoF9RrUJqb3oAtX3XgnrtSUarRIaV24sLrypxa9C1LirkDg8cwxGHcGv+TNe1bP+d68X2E6nTKZTJjNZqxWK/K5vPzJdnePenaXZmuXtHVPaZdO7z7p1g4ijEhiSVqvUa1WkVJSCUNCpcJyuWQ9a+DV1YLXgy+8Ovqk/LP2wfCcp8/eUWt1kY2MKIpwHAfDNCmXy5RMpVKJwnw+16C11sDjkzHD0TflF9pP3v7g8OVHGukWMuvQbW+TxREyCgkCge97+EJQWCwWf7b7GzgcXfDm+FxrMPzK6HTMQf+MpN7ErkiE5xJ6NsK18RwLx7axbOffwHy73Een3zk4PCOupbje7208Eei3l8v3FdDeBDim/+KD3jAvQCY1pTpp2qTd6dBKGyQVsTmwvdPDrwS6ENM0sKwyN4omjx7e4fmTB5tHzno3VVShmzVVwznw2vUij/f3GPT3NwT235Pcuk2QJNiWhWEYWkV1LqHwaET/EbmpIjvqRCwFtFUJuhDXRcQxQn30C9wpJKaWDJtrAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "step-3",
              "title": "step-3",
              "src": "/static/32fd7f0ab578d29c7a122bd539d93f34/e3189/step-3.png",
              "srcSet": ["/static/32fd7f0ab578d29c7a122bd539d93f34/a2ead/step-3.png 259w", "/static/32fd7f0ab578d29c7a122bd539d93f34/6b9fd/step-3.png 518w", "/static/32fd7f0ab578d29c7a122bd539d93f34/e3189/step-3.png 1035w", "/static/32fd7f0ab578d29c7a122bd539d93f34/44d59/step-3.png 1553w", "/static/32fd7f0ab578d29c7a122bd539d93f34/a6d66/step-3.png 2070w", "/static/32fd7f0ab578d29c7a122bd539d93f34/b918a/step-3.png 2780w"],
              "sizes": "(max-width: 1035px) 100vw, 1035px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>
      </div>
      <br></br></undefined>
    <h3>{`Making the SideMenu reusable`}</h3>
    <p>{`To make the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SideMenu`}</code>{` flexible and reusable, we can pass the content as a child prop from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`App.js`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// App.js`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`App`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` activeMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`header className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"app-header"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Omit some lines`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`SideMenu
          isMenuActive`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          onOverLayClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`activeMenu`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`isMenuActive`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`ul`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`li`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{`hello world`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`li`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`/`}</span>{`ul`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`SideMenu`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`header`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The SideMenu will render children as prop.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` React `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SideMenu`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// omit some lines`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`aside className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`sideMenuClasses`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"side-menu__overlay"`}</span>{` onClick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`onOverLayClick`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div className`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`sideMenuContentClasses`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`          `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`children`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`aside`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`By doing, we can reuse this component to different projects without modifying it directly.`}</p>
    <h3>{`Adding CSS for animation`}</h3>
    <p>{`That is all we need on React side, the final step is to make use of CSS animation magic.
In this case, there are three properties we need to pay attention to. `}</p>
    <ul>
      <li parentName="ul">{`First, we will use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`transition`}</code>{` to create the animation effect.`}</li>
      <li parentName="ul">{`Next, to make the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`side-menu__content`}</code>{` move in and out of our web page from the left, we will make use of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`transform: translateX()`}</code>{`. `}</li>
      <li parentName="ul">{`Finally, last but not least, we will have to use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`pointer-events: auto`}</code>{` on our `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`side-menu`}</code>{` so that the overlay can work as expected. `}</li>
    </ul>
    <p>{`Let's modify our css code a little bit`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu__content`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-sizing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` relative`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 10`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 70%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`max-width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 26rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #fff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0 2rem `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 255`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transform`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`translateX`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`-103%`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transition`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` transform 300ms linear`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu__content--active`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transform`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` none`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu__overlay`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100%`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #0c1066`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`opacity`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`transition`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` opacity 300ms linear`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu--active`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`pointer-events`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` auto`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.side-menu--active .side-menu__overlay`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`opacity`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0.3`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Since the initial state of our menu is inactive, we set the value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`translateX`}</code>{` to -100% to keep the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu__content`}</code>{` our of our view.
When the menu is active, we turn off the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform`}</code>{` so the content can slide into our view.
We want `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transition`}</code>{` to be only effective on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transform`}</code>{` at 300ms and linear speed. `}</p>
    <p>{`The same method is applied to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`overlay`}</code>{`, but with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`opacity`}</code>{` instead. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`overlay`}</code>{` will have an opacity of 0 when the menu inactive and is given a non-zero opacity when the menu is active.`}</p>
    <p>{`Remember that we set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`side-menu`}</code>{` to have a width and height of our screen view, even though the component is out of the view, it still exists in the DOM.
That is why we need to have `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pointer-events: none;`}</code>{` at the beginning to prevent all clicks, state and cursor options on the SideMenu.
Therefore, when the menu is active, we need to enable that option back so that the component would respond to any touch/mouse event.`}</p>
    <p>{`That's it! Thank you for taking your time to read the blog. :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      